<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <components
          :is="tab.component"
          :attachInfo.sync="attachInfo"
          :isApprDisabled="true"
          :tabParam.sync="tbmData"
          @updateMode="updateMode"
          @closePopup="closePopup"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar';
export default {
  name: 'tbm-detail-appr',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          tbmId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'tbmInfo',
      tabItems: [
        { key: uid(), name: 'tbmInfo', icon: 'info', label: 'TBM 기본정보', component: () => import(`${'@/pages/sai/tbm/tbmInfo.vue'}`) }
      ],
      addTabItems: [
        { key: uid(), name: 'workerHealthStatus', icon: 'groups', label: '참석자 및 건강상태', component: () => import(`${'@/pages/sai/tbm/tbmWorkerHealthStatus.vue'}`) },
        { key: uid(), name: 'relationMachinery', icon: 'precision_manufacturing', label: '관련 기계 및 장비', component: () => import(`${'@/pages/sai/tbm/tbmRelationMachinery.vue'}`) },
      ],
      getUrl: '',
      tbmData: {
        tbmId: '',
        tbmNo: '',
        plantCd: '',
        mdmSopId: '',
        sopName: '',
        tbmWorkResponsibleId: '',
        tbmWorkResponsibleName: '',
        tbmWorkDate: '',
        tbmWorkArea: '',
        tbmWorkContents: '',
        tbmCompleteFlag: '',
        processCd: '',
        vendorCd: '',
        sopWorkPermitId: '',
        tbmWorkPermitFlag: 'N',
        preInspections: '',

        tbmAttendeeModelList: [],
        tbmRiskHazardModelList: [],
        tbmHazardEquipModelList: [],
        tbmHazardEquipModelResultList: [],

        deleteTbmAttendeeModelList: [],
        deleteTbmRiskHazardModelList: [],
        deleteTbmHazardEquipModelList: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'TBM_EDUCATION',
        taskKey: '',
      },
      getRespondUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.tbmId != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      this.getUrl = selectConfig.sai.tbm.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData = this.$_.clone(_result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.tbmId);
        },);
      }
    },
    updateMode(_tbmId) {
      this.popupParam.tbmId = _tbmId;

      this.getDetail();

      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>